<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="http://www.timrb.de">
            Notfall
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href=""> Hilfe </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="http://www.eventversicherungen.com">
            Event
          </a>
        </li>
      </ul>
     
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
